import React from 'react'
import { MyAxios as axios } from '../MyAxios'
import { WithSession } from '../session/SessionProvider'

export const BackendContext = React.createContext()

class BackendProviderCls extends React.Component {
  addBasePath (path) {
    return `/api/v1${path}`
  }

  async makeBackendCall (method, path, data = undefined, options = {}, params = undefined) {
    let pathToUse
    if (options.addBasePath === false) {
      pathToUse = path
    } else {
      pathToUse = this.addBasePath(path)
    }
    return axios({
      method,
      url: pathToUse,
      headers: { ...this.props.authorizationHeaders(), 'Content-Type': 'application/json' },
      data,
      params
    })
  }

  // Questionnaires

  async getResponsesPlayerResponses (playerIds, start, end) {
    let url = `/responses/player_responses?start=${start.toISOString()}&end=${end.toISOString()}`
    const sortedPlayerIds = playerIds.sort()
    for (const playerId of sortedPlayerIds) {
      url += `&player_ids[]=${playerId}`
    }
    return this.makeBackendCall('GET', url)
  }

  async getPolarTrainingSessionsIndex (playerIds, start, end) {
    let url = `/polar_training_sessions?start=${start.toISOString()}&end=${end.toISOString()}`
    const sortedPlayerIds = playerIds.sort()
    for (const playerId of sortedPlayerIds) {
      url += `&player_ids[]=${playerId}`
    }
    return this.makeBackendCall('GET', url)
  }

  async getPlayerNetworks (playerId, limit) {
    return this.makeBackendCall('GET', `/networks/player_networks?player_id=${playerId}&limit=${limit}`)
  }

  render () {
    return (
      <BackendContext.Provider value={{
        backend: {
          responses: {
            playerResponses: this.getResponsesPlayerResponses.bind(this)
          },
          polarTrainingSessions: {
            index: this.getPolarTrainingSessionsIndex.bind(this)
          },
          networks: {
            playerNetworks: this.getPlayerNetworks.bind(this)
          },
          ...this.state
        }
      }}
      >
        {this.props.children}
      </BackendContext.Provider>
    )
  }
}

export const BackendProvider = WithSession(BackendProviderCls)

export const WithBackend = Component => props => {
  return (
    <BackendContext.Consumer>
      {(context) => (<Component {...context} {...props} />)}
    </BackendContext.Consumer>
  )
}
