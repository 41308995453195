import React, { useState } from 'react'
import I18n from 'i18n'
import { Network } from '../../common/types/NetworksPlayerNetworksDTO'

interface Props {
  networks: Network[]
}

const NetworksSlider: React.FunctionComponent<Props> = (props: Props) => {
  const [selectedNetworkIndex, setSelectedNetworkIndex] = useState<number>(0)
  const network: Network | null = selectedNetworkIndex <= props.networks.length ? props.networks[selectedNetworkIndex] : null

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedNetworkIndex(parseInt(event.target.value, 10))
  }

  return (
    <div>
      {props.networks.length > 1 && (
        <>
          <p>{I18n.t('visualizations.networks.slide_to_view_different_networks')}</p>
          <div className='row'>
            <div className='col s12 m6'>
              <input
                type='range'
                min='0'
                max={props.networks.length - 1}
                step='1'
                value={selectedNetworkIndex}
                onChange={handleSliderChange}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col s6'>
              <p>{I18n.t('visualizations.networks.network_at_index')}: {selectedNetworkIndex}</p>
            </div>
            <div className='col s6 m3'>
              <p>{I18n.t('visualizations.networks.network_count')}: {props.networks.length}</p>
            </div>
          </div>
        </>
      )}
      {(network != null) && (
        <>
          <div className='row'>
            <div className='col s12'>
              <h5>{I18n.t('visualizations.networks.network_details')}</h5>
            </div>
          </div>
          <div className='row'>
            <div className='col s6'>
              <p>{I18n.t('visualizations.networks.start_date')}: {network.start_date}</p>
            </div>
            <div className='col s6'>
              <p>{I18n.t('visualizations.networks.end_date')}: {network.end_date}</p>
            </div>
          </div>
          {network.network !== null && (
            <div className='row'>
              <div className='col s12'>
                <img
                  src={network.network}
                  alt={`${I18n.t('visualizations.networks.network_at_index') as string} ${selectedNetworkIndex}`}
                />
              </div>
            </div>
          )}
          {network.error_message !== null && (
            <div className='row'>
              <div className='col s12'>
                <p>{I18n.t('visualizations.networks.error')}: {network.error_message}</p>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default NetworksSlider
