import React, { useEffect, useState } from 'react'
import I18n from 'i18n'
import Teams, { Team } from '../../common/types/Teams'
import { useNetworksPlayerNetworksQuery } from '../../backend/Queries'
import SpinnerWrapper from '../../common/SpinnerWrapper'
import NetworksSlider from './NetworksSlider'

interface Props {
  teams: Teams
}

const NetworksDashboard: React.FunctionComponent<Props> = (props: Props) => {
  const [selectedPlayerId, setSelectedPlayerId] = useState<number | undefined>(undefined)
  const limit = 90

  const { data: networks = [], refetch, isSuccess, isError } = useNetworksPlayerNetworksQuery(selectedPlayerId, limit)

  useEffect(() => {
    // TODO: don't ignore errors for refetch
    void refetch()
  }, [selectedPlayerId])

  useEffect(() => {
    (window as any).M.FormSelect.init(document.querySelectorAll('#player-selector'))
  }, [props.teams])

  useEffect(() => {
    const elem = (document.getElementById('player-selector') as any)?.M_FormSelect
    if (elem !== undefined && elem !== null) {
      elem._setValueToInput()
      elem._setSelectedStates()
    }
  }, [selectedPlayerId])

  const renderPlayerOptions = (): React.ReactElement => {
    return (
      <>
        {props.teams.map((team: Team) => {
          return (
            <optgroup label={team.name} key={team.name}>
              {team.players.map(player => (
                <option value={player.id} key={player.id}>{player.name}</option>
              ))}
            </optgroup>
          )
        })}
      </>
    )
  }

  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <h5>{I18n.t('visualizations.tabs.networks.title')}</h5>
        </div>
        <div className='input-field col s12 m4'>
          <select
            id='player-selector'
            value={selectedPlayerId !== undefined ? `${selectedPlayerId}` : undefined}
            onChange={() => {
              const selectedValue = [...Array.from((document.getElementById('player-selector') as HTMLSelectElement).options)]
                .filter((x) => x.selected)
                .map((x) => x.value)[0]
              setSelectedPlayerId(!isNaN(parseInt(selectedValue)) ? parseInt(selectedValue) : undefined)
            }}
          >
            <option value='' />
            {renderPlayerOptions()}
          </select>
          <label>{I18n.t('visualizations.labels.players')}</label>
        </div>
      </div>
      <SpinnerWrapper ready={isSuccess} failed={isError} transparent>
        {networks.length === 0 && selectedPlayerId !== undefined && (
          <p><em>{I18n.t('visualizations.networks.there_are_no_networks')}</em></p>
        )}
        {networks.length > 0 && (
          <>
            <NetworksSlider networks={networks} />
          </>
        )}
      </SpinnerWrapper>
    </>
  )
}

export default NetworksDashboard
