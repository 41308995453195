export const TRAINING_TYPES = [
  'group',
  'strength',
  'absent',
  'recovery_training',
  'rehab_combi',
  'rehab_strength',
  'rehab_cardio',
  'individual',
  'massage'
]

export const TEAM_TRAINING_TYPES = [
  'training',
  'match'
]

export const MONDAY = 'monday'
export const TUESDAY = 'tuesday'
export const WEDNESDAY = 'wednesday'
export const THURSDAY = 'thursday'
export const FRIDAY = 'friday'
export const SATURDAY = 'saturday'
export const SUNDAY = 'sunday'
export const MATCHDAY = 'match_day'

export const WEEKDAYS = [
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
  MATCHDAY
]

export const BEFORE_THE_FIRST_TRAINING = 'before_the_first_training'
export const AFTER_THE_LAST_TRAINING = 'after_the_last_training'
export const AFTER_THE_FIRST_TRAINING = 'after_the_first_training'
export const INDEPENDENT_OF_A_TRAINING = 'independent_of_a_training'
export const BEFORE_MATCH = 'before_match'
export const AFTER_MATCH = 'after_match'

export const RECOVERY_TICK_LABELS = [
  '6.',
  { nl: '7. heel, heel slecht hersteld', en: '7. very, very poor recovery' },
  '8.',
  { nl: '9. heel slecht hersteld', en: '9. very poor recovery' },
  '10.',
  { nl: '11. slecht hersteld', en: '11. poor recovery' },
  '12.',
  { nl: '13. redelijk hersteld', en: '13. reasonable recovery' },
  '14.',
  { nl: '15. goed hersteld', en: '15. good recovery' },
  '16.',
  { nl: '17. heel goed hersteld', en: '17. very good recovery' },
  '18.',
  { nl: '19. heel, heel goed hersteld', en: '19. very, very good recovery' },
  '20.'
]

export const INTENSITY_TICK_LABELS = [
  '6.',
  { nl: '7. heel, heel licht inspannend', en: '7. very, very light' },
  '8.',
  { nl: '9. heel licht inspannend', en: '9. very light' },
  '10.',
  { nl: '11. licht inspannend', en: '11. fairly light' },
  '12.',
  { nl: '13. redelijk inspannend', en: '13. somewhat hard' },
  '14.',
  { nl: '15. inspannend', en: '15. hard' },
  '16.',
  { nl: '17. heel inspannend', en: '17. very hard' },
  '18.',
  { nl: '19. heel, heel inspannend', en: '19. very, very hard' },
  '20.'
]

export const KEEPER = 'keeper'
export const DEFENDER = 'defender'
export const MIDFIELDER = 'midfielder'
export const ATTACKER = 'attacker'
export const COACH = 'coach'

export const ROLES = [KEEPER, DEFENDER, MIDFIELDER, ATTACKER]

export const NL = 'nl'
export const EN = 'en'
export const LOCALES = [NL, EN]

export const UNKNOWN_ICON = 'person'
export const KEEPER_ICON = 'GK'
export const ATTACKER_ICON = 'ATT'
export const DEFENDER_ICON = 'DEF'
export const MIDFIELDER_ICON = 'MID'

export const TEAM_ICON = 'folder_shared'
export const TEAM_ICON_EMPTY = 'folder'

export const TODAY = 'today'
export const YESTERDAY = 'yesterday'
export const PAST_7_DAYS = 'past_7_days'
export const EVERYTHING = 'everything'
export const CUSTOM = 'custom'

// Questionnaire constants

const questionnairesTemp = {
  [COACH]: [
    {
      type: 'slider',
      required: true,
      id: 'v0',
      min: 0,
      max: 100,
      label: {
        nl: 'Hoe goed heeft deze speler gepresteerd in de afgelopen wedstrijd of week?',
        en: 'How did this player perform in this match or week?'
      },
      minLabel: { nl: 'zeer slecht (ver onder zijn kunnen)', en: 'very bad (far below his capabilities)' },
      maxLabel: { nl: 'maximaal (op de top van zijn kunnen)', en: 'maximally (to the best of his capabilities)' }
    }
  ],
  [BEFORE_THE_FIRST_TRAINING]: [
    {
      type: 'slider',
      required: true,
      id: 'v1',
      min: 6,
      max: 20,
      label: { nl: 'Hoe goed ben je hersteld?', en: 'How is your recovery?' },
      vertical: true,
      tickLabels: RECOVERY_TICK_LABELS
    },
    {
      type: 'slider',
      required: true,
      id: 'v2',
      min: 0,
      max: 100,
      label: { nl: 'Hoe zeker ben je ervan dat je vandaag maximaal kan presteren?', en: 'How confident are you that you can perform maximally today?' },
      minLabel: { nl: 'helemaal niet zeker', en: 'not at all confident' },
      maxLabel: { nl: 'heel erg zeker', en: 'very confident' }
    },
    {
      type: 'slider',
      required: true,
      id: 'v3',
      min: 0,
      max: 100,
      label: { nl: 'Hoe gemotiveerd ben je om vandaag maximaal te presteren?', en: 'How motivated are you to perform maximally today?' },
      minLabel: { nl: 'helemaal niet gemotiveerd', en: 'not at all motivated' },
      maxLabel: { nl: 'heel erg gemotiveerd', en: 'very much motivated' }
    },
    {
      type: 'slider',
      required: true,
      id: 'v4',
      min: 0,
      max: 100,
      label: { nl: 'Hoeveel zin heb je in de training(en) vandaag?', en: 'How much are you in the mood to train today?' },
      minLabel: { nl: 'helemaal geen zin', en: 'not at all in the mood' },
      maxLabel: { nl: 'heel veel zin', en: 'very much in the mood' }
    }
  ],
  [AFTER_THE_LAST_TRAINING]: [
    {
      type: 'slider',
      required: true,
      id: 'v5',
      min: 6,
      max: 20,
      label: { nl: 'Hoe inspannend was deze training?', en: 'How hard was the training?' },
      vertical: true,
      tickLabels: INTENSITY_TICK_LABELS
    },
    {
      type: 'slider',
      required: true,
      id: 'v6',
      min: 0,
      max: 100,
      label: { nl: 'Hoe goed heb je vandaag gepresteerd?', en: 'How well did you perform today?' },
      minLabel: { nl: 'zeer slecht (ver onder mijn kunnen)', en: 'very bad (far below my capabilities)' },
      maxLabel: { nl: 'maximaal (op de top van mijn kunnen)', en: 'maximally (to the best of my capabilities)' }
    },
    {
      type: 'slider',
      required: true,
      id: 'v7',
      min: 0,
      max: 100,
      label: { nl: 'Hoe leuk vond je de training(en) vandaag?', en: 'How much did you enjoy the training session(s) today?' },
      minLabel: { nl: 'helemaal niet leuk', en: 'not at all' },
      maxLabel: { nl: 'heel erg leuk', en: 'very much' }
    }
  ],
  [AFTER_THE_FIRST_TRAINING]: [
    {
      type: 'slider',
      required: true,
      id: 'v8',
      min: 6,
      max: 20,
      label: { nl: 'Hoe inspannend was deze training?', en: 'How hard was the training?' },
      vertical: true,
      tickLabels: INTENSITY_TICK_LABELS
    }
  ],
  [INDEPENDENT_OF_A_TRAINING]: [
    {
      type: 'radio',
      required: true,
      id: 'v16',
      label: { nl: 'Is er in de afgelopen week iets belangrijks gebeurd?', en: 'Did something important happen in the last week?' },
      options: [{ value: 'Ja', label: { nl: 'Ja', en: 'Yes' } }, { value: 'Nee', label: { nl: 'Nee', en: 'No' } }]
    },
    {
      type: 'slider',
      required: true,
      id: 'v17',
      min: 0,
      max: 100,
      label: { nl: 'Hoe negatief of positief was deze gebeurtenis?', en: 'How negative or positive was this event?' },
      minLabel: { nl: 'zeer negatief', en: 'very negative' },
      maxLabel: { nl: 'zeer positief', en: 'very positive' },
      showOnlyIf: [
        { v16: 'Ja' }
      ]
    },
    {
      type: 'checkbox',
      required: true,
      id: 'v18',
      label: { nl: 'Waar was deze gebeurtenis aan gerelateerd?', en: 'What was this event related to?' },
      options: [
        { value: 1, label: { nl: 'Mezelf', en: 'Myself' } },
        { value: 2, label: { nl: 'Thuissituatie/ hechte familie/ dierbaren', en: 'Home situation/ close family/ significant others' } },
        { value: 3, label: { nl: 'Vrienden/ andere familie/ kennissen', en: 'Friends/ other family/ acquaintances' } },
        { value: 4, label: { nl: 'School', en: 'School' } },
        { value: 5, label: { nl: 'Maatschappij/ nieuws', en: 'Society/ news' } },
        { value: 6, label: { nl: 'Openbare ruimte/ vreemden', en: 'Public space/ strangers' } },
        { value: 7, label: { nl: 'Club/ team', en: 'Club/ team' } },
        { value: 8, label: { nl: 'Anders', en: 'Other' } }
      ],
      showOnlyIf: [
        { v16: 'Ja' }
      ]
    },
    {
      type: 'raw',
      id: 'u1',
      label: { en: 'OSTRC explanation', nl: 'OSTRC uitleg' },
      html: {
        nl: '<p>Vul alsjeblieft de onderstaande vragen in, ongeacht of je in de afgelopen 7 dagen gezondheidsproblemen hebt ervaren. Kies het antwoord dat voor jou het meest van toepassing is. Als je het niet zeker weet, probeer dan de vraag zo goed mogelijk te beantwoorden.</p><p>Een gezondheidsprobleem is elke verandering die jij als een vermindering van je normale gezondheidstoestand ziet, ongeacht de consequenties op je sportdeelname of prestaties en of je er medische hulp voor gezocht hebt. Dit omvat, maar is niet beperkt tot, een blessure, ziekte, pijn of mentale problemen.</p>',
        en: '<p>Please answer all questions regardless of whether or not you have experienced health problems in the past 7 days. Select the alternative that is most appropriate for you, and in the case that you are unsure, try to answer as best you can anyway.</p><p>A health problem is any condition that you consider to be a reduction in your normal state of full health, irrespective of its consequences on your sports participation or performance, or whether you have sought medical attention. This may include, but is not limited to, injury, illness, pain or mental health conditions.</p>'
      }
    },
    {
      type: 'radio',
      required: true,
      id: 'v19',
      label: { nl: 'Heb je in de afgelopen 7 dagen tijdens het sporten hinder ondervonden van een blessure, ziekte of andere gezondheidsklachten?', en: 'Have you had any difficulties participating in normal training and competition due to injury, illness or other health problems during the past 7 days?' },
      options: [
        { value: 0, label: { nl: 'Ik heb volledig deelgenomen zonder gezondheidsklachten', en: 'Full participation without health problems' } },
        { value: 1, label: { nl: 'Ik heb volledig deelgenomen, maar had wel hinder van een gezondheidsklacht', en: 'Full participation, but with a health problem' } },
        { value: 2, label: { nl: 'Ik heb gedeeltelijk deelgenomen vanwege een gezondheidsklacht', en: 'Reduced participation due to a health problem' } },
        { value: 3, label: { nl: 'Ik heb helemaal niet deelgenomen vanwege een gezondheidsklacht', en: 'Could not participate due to a health problem' } }
      ]
    },
    {
      type: 'radio',
      required: true,
      id: 'v20',
      label: { nl: 'In welke mate heb je in de afgelopen 7 dagen je training of deelname aan wedstrijden aangepast vanwege deze blessure, ziekte of andere gezondheidsklachten?', en: 'To what extent have you modified your training or competition due to injury, illness or other health problems during the past week?' },
      options: [
        { value: 0, label: { nl: 'Dit heb ik niet aangepast', en: 'No modification' } },
        { value: 1, label: { nl: 'Enigszins', en: 'To a minor extent' } },
        { value: 2, label: { nl: 'Matig', en: 'To a moderate extent' } },
        { value: 3, label: { nl: 'Veel', en: 'To a major extent' } }
      ]
    },
    {
      type: 'radio',
      required: true,
      id: 'v21',
      label: { nl: 'In hoeverre heb je in de afgelopen 7 dagen gemerkt dat deze blessure, ziekte of andere gezondheidsklachten je prestatie heeft beïnvloed?', en: 'To what extent has injury, illness or other health problems affected your performance during the past 7 days?' },
      options: [
        { value: 0, label: { nl: 'Mijn prestatie was niet beïnvloed', en: 'No effect' } },
        { value: 1, label: { nl: 'Enigszins', en: 'To a minor extent' } },
        { value: 2, label: { nl: 'Matig', en: 'To a moderate extent' } },
        { value: 3, label: { nl: 'Veel', en: 'To a major extent' } }
      ]
    },
    {
      type: 'radio',
      required: true,
      id: 'v22',
      label: { nl: 'In welke mate heb je in de afgelopen 7 dagen last gehad van de symptomen van deze blessure, ziekte of andere gezondheidsklachten?', en: 'To what extent have you experienced symptoms/health complaints during the past 7 days?' },
      options: [
        { value: 0, label: { nl: 'Ik had geen last', en: 'No symptoms/health complaints' } },
        { value: 1, label: { nl: 'Enigszins', en: 'To a mild extent' } },
        { value: 2, label: { nl: 'Matig', en: 'To a moderate extent' } },
        { value: 3, label: { nl: 'Veel', en: 'To a severe extent' } }
      ]
    }
  ],
  [BEFORE_MATCH]: [
    {
      type: 'slider',
      required: true,
      id: 'v9',
      min: 6,
      max: 20,
      label: { nl: 'Hoe goed ben je hersteld?', en: 'How is your recovery?' },
      vertical: true,
      tickLabels: RECOVERY_TICK_LABELS
    },
    {
      type: 'slider',
      required: true,
      id: 'v10',
      min: 0,
      max: 100,
      label: { nl: 'Hoe zeker ben je ervan dat je vandaag maximaal kan presteren?', en: 'How confident are you that you can perform maximally today?' },
      minLabel: { nl: 'helemaal niet zeker', en: 'not at all confident' },
      maxLabel: { nl: 'heel erg zeker', en: 'very confident' }
    },
    {
      type: 'slider',
      required: true,
      id: 'v11',
      min: 0,
      max: 100,
      label: { nl: 'Hoe gemotiveerd ben je om vandaag maximaal te presteren?', en: 'How motivated are you to perform maximally today?' },
      minLabel: { nl: 'helemaal niet gemotiveerd', en: 'not at all motivated' },
      maxLabel: { nl: 'heel erg gemotiveerd', en: 'very much motivated' }
    },
    {
      type: 'slider',
      required: true,
      id: 'v12',
      min: 0,
      max: 100,
      label: { nl: 'Hoeveel zin heb je in de wedstrijd vandaag?', en: 'How much are you in the mood to play the match today?' },
      minLabel: { nl: 'helemaal geen zin', en: 'not at all in the mood' },
      maxLabel: { nl: 'heel veel zin', en: 'very much in the mood' }
    }
  ],
  [AFTER_MATCH]: [
    {
      type: 'slider',
      required: true,
      id: 'v13',
      min: 6,
      max: 20,
      label: { nl: 'Hoe inspannend was deze wedstrijd?', en: 'How hard was the match?' },
      vertical: true,
      tickLabels: INTENSITY_TICK_LABELS
    },
    {
      type: 'slider',
      required: true,
      id: 'v14',
      min: 0,
      max: 100,
      label: { nl: 'Hoe goed heb je vandaag gepresteerd?', en: 'How good did you perform today?' },
      minLabel: { nl: 'zeer slecht (ver onder mijn kunnen)', en: 'very bad (far below my capabilities)' },
      maxLabel: { nl: 'maximaal (op de top van mijn kunnen)', en: 'maximally (to the best of my capabilities)' }
    },
    {
      type: 'slider',
      required: true,
      id: 'v15',
      min: 0,
      max: 100,
      label: { nl: 'Hoe leuk vond je de wedstrijd vandaag?', en: 'How much did you enjoy the match today?' },
      minLabel: { nl: 'helemaal niet leuk', en: 'not at all' },
      maxLabel: { nl: 'heel erg leuk', en: 'very much' }
    }
  ]
}

if (process.env.MUSCLE_SORENESS_QUESTIONS === 'true') {
  questionnairesTemp[BEFORE_THE_FIRST_TRAINING].push(
    {
      type: 'slider',
      required: true,
      id: 'v23',
      min: 0,
      max: 100,
      label: {
        nl: 'Hoeveel spierpijn heb je op dit moment?',
        en: 'How much muscle soreness are you experiencing currently?'
      },
      minLabel: { nl: 'helemaal geen spierpijn', en: 'no muscle soreness at all' },
      maxLabel: { nl: 'heel veel spierpijn', en: 'very much muscle soreness' }
    }
  )
}

if (process.env.SLEEP_QUESTIONS === 'true') {
  questionnairesTemp[BEFORE_THE_FIRST_TRAINING].push(
    {
      type: 'slider',
      required: true,
      id: 'v24',
      min: 0,
      max: 20,
      step: 0.5,
      label: {
        nl: 'Hoeveel uren heb je geslapen in de afgelopen nacht?',
        en: 'How many hours did you sleep last night?'
      },
      minLabel: { nl: '0 uur', en: '0 hours' },
      maxLabel: { nl: '20 uur', en: '20 hours' }
    },
    {
      type: 'slider',
      required: true,
      id: 'v25',
      min: 0,
      max: 100,
      label: {
        nl: 'Hoe was de kwaliteit van je slaap in de afgelopen nacht?',
        en: 'How was your sleep quality last night?'
      },
      minLabel: { nl: 'helemaal niet goed', en: 'not good at all' },
      maxLabel: { nl: 'heel erg goed', en: 'very good' }
    }
  )
}

if (process.env.HYDRATION_QUESTIONS === 'true') {
  questionnairesTemp[BEFORE_THE_FIRST_TRAINING].push(
    {
      type: 'slider',
      required: true,
      id: 'v26',
      min: 0,
      max: 100,
      label: {
        nl: 'Heb je het gevoel dat je uitgedroogd bent op dit moment?',
        en: 'Do you feel dehydrated at the moment?'
      },
      minLabel: { nl: 'helemaal niet uitgedroogd', en: 'fully hydrated' },
      maxLabel: { nl: 'heel erg uitgedroogd', en: 'fully dehydrated' }
    }
  )
  questionnairesTemp[AFTER_THE_LAST_TRAINING].push(
    {
      type: 'radio',
      required: true,
      id: 'v27',
      label: {
        nl: 'Heb je het gevoel dat je genoeg hebt gedronken tijdens de training?',
        en: 'Do you feel you had enough to drink during practice?'
      },
      options: [
        { value: 0, label: { nl: 'te weinig', en: 'not enough' } },
        { value: 1, label: { nl: 'voldoende', en: 'enough' } },
        { value: 2, label: { nl: 'te veel', en: 'too much' } }
      ]
    },
    {
      type: 'radio',
      required: true,
      id: 'v28',
      label: {
        nl: 'Heb je vandaag anders gedronken dan normaal tijdens training?',
        en: 'Did you drink differently during today\'s practice compared to other practices?'
      },
      options: [
        { value: 0, label: { nl: 'ja, meer dan normaal', en: 'yes, more than normal' } },
        { value: 1, label: { nl: 'ja, minder dan normaal', en: 'yes, less than normal' } },
        { value: 2, label: { nl: 'nee, vergelijkbaar', en: 'no, comparable' } }
      ]
    },
    {
      type: 'slider',
      required: true,
      id: 'v29',
      min: 0,
      max: 100,
      label: {
        nl: 'Heb je het gevoel dat je uitgedroogd bent op dit moment?',
        en: 'Do you feel dehydrated at the moment?'
      },
      minLabel: { nl: 'helemaal niet uitgedroogd', en: 'fully hydrated' },
      maxLabel: { nl: 'heel erg uitgedroogd', en: 'fully dehydrated' }
    },
    {
      type: 'slider',
      required: true,
      id: 'v30',
      min: 0,
      max: 100,
      label: {
        nl: 'Heb je veel dorst gehad vandaag tijdens training?',
        en: 'Were you thirsty during today\'s practice?'
      },
      minLabel: { nl: 'helemaal geen dorst', en: 'not thirsty at all' },
      maxLabel: { nl: 'heel veel dorst', en: 'very thirsty' }
    }
  )
}

if (process.env.TRAINING_DURATION_QUESTIONS === 'true') {
  questionnairesTemp[AFTER_THE_FIRST_TRAINING].push(
    {
      type: 'slider',
      required: true,
      id: 'v31',
      min: 0,
      max: 180,
      step: 5,
      label: {
        nl: 'Hoeveel minuten duurde je laatste training?',
        en: 'How many minutes was your last training?'
      },
      minLabel: { nl: '0 minuten', en: '0 minutes' },
      maxLabel: { nl: '3 uur', en: '3 hours' }
    }
  )
  questionnairesTemp[AFTER_THE_LAST_TRAINING].push(
    {
      type: 'slider',
      required: true,
      id: 'v32',
      min: 0,
      max: 180,
      step: 5,
      label: {
        nl: 'Hoeveel minuten duurde je laatste training?',
        en: 'How many minutes was your last training?'
      },
      minLabel: { nl: '0 minuten', en: '0 minutes' },
      maxLabel: { nl: '3 uur', en: '3 hours' }
    }
  )
  questionnairesTemp[AFTER_MATCH].push(
    {
      type: 'slider',
      required: true,
      id: 'v33',
      min: 0,
      max: 180,
      step: 5,
      label: {
        nl: 'Hoeveel minuten heb je gespeeld in de wedstrijd?',
        en: 'How many minutes did you play in the match?'
      },
      minLabel: { nl: '0 minuten', en: '0 minutes' },
      maxLabel: { nl: '3 uur', en: '3 hours' }
    }
  )
}

if (process.env.TDT_QUESTIONS === 'true') {
  questionnairesTemp[AFTER_THE_FIRST_TRAINING].push(
    {
      type: 'slider',
      required: true,
      id: 'v31',
      min: 0,
      max: 420,
      step: 5,
      label: {
        nl: 'Hoeveel minuten duurde je laatste training?',
        en: 'How many minutes was your last training?'
      },
      minLabel: { nl: '0 minuten', en: '0 minutes' },
      maxLabel: { nl: '7 uur', en: '7 hours' }
    }
  )
  questionnairesTemp[AFTER_THE_LAST_TRAINING].push(
    {
      type: 'slider',
      required: true,
      id: 'v32',
      min: 0,
      max: 420,
      step: 5,
      label: {
        nl: 'Hoeveel minuten duurde je laatste training?',
        en: 'How many minutes was your last training?'
      },
      minLabel: { nl: '0 minuten', en: '0 minutes' },
      maxLabel: { nl: '7 uur', en: '7 hours' }
    }
  )
  questionnairesTemp[AFTER_THE_LAST_TRAINING].push(
    {
      type: 'radio',
      required: true,
      id: 'v34',
      label: {
        nl: 'Wat voor training heb je vandaag gedaan?',
        en: 'What kind of training did you do today?'
      },
      options: [
        { value: 'Geen training / alleen core', label: { nl: 'Geen training / alleen core', en: 'No training / only core' } },
        { value: 'Hersteltraining (+ eventueel core)', label: { nl: 'Hersteltraining (+ eventueel core)', en: 'Recovery training (+ possibly core)' } },
        { value: 'Krachttraining', label: { nl: 'Krachttraining', en: 'Strength training' } },
        { value: 'Krachttraining + fietstraining', label: { nl: 'Krachttraining + fietstraining', en: 'Strength training + cycling' } },
        { value: 'Intervaltraining', label: { nl: 'Intervaltraining', en: 'Interval training' } },
        { value: 'Intervaltraining + duurtraining', label: { nl: 'Intervaltraining + duurtraining', en: 'Interval training + endurance training' } },
        { value: 'Duurtraining', label: { nl: 'Duurtraining', en: 'Endurance training' } }
      ]
    }
  )
  questionnairesTemp[AFTER_MATCH].push(
    {
      type: 'slider',
      required: true,
      id: 'v33',
      min: 0,
      max: 420,
      step: 5,
      label: {
        nl: 'Hoeveel minuten heb je gespeeld in de wedstrijd?',
        en: 'How many minutes did you play in the match?'
      },
      minLabel: { nl: '0 minuten', en: '0 minutes' },
      maxLabel: { nl: '7 uur', en: '7 hours' }
    }
  )
}

if (process.env.BATTERY_QUESTIONS === 'true') {
  questionnairesTemp[BEFORE_THE_FIRST_TRAINING].push(
    {
      type: 'battery',
      required: true,
      id: 'v43',
      label: {
        nl: 'Hoe jij je op dit moment voelt, zou je kunnen uitdrukken in termen van de laadtoestand van een batterij. Dit kan variëren van “uitgeput” tot “vol energie”. Geef hier aan welk plaatje het beste jouw huidige toestand weergeeft.',
        en: 'How you feel at the moment can be expressed in terms of the charge level of a battery. This can range from "exhausted" to "full of energy". Indicate below which image best represents your current state.'
      }
    }
  )

  questionnairesTemp[AFTER_THE_LAST_TRAINING].push(
    {
      type: 'battery',
      required: true,
      id: 'v44',
      label: {
        nl: 'Hoe jij je op dit moment voelt, zou je kunnen uitdrukken in termen van de laadtoestand van een batterij. Dit kan variëren van “uitgeput” tot “vol energie”. Geef hier aan welk plaatje het beste jouw huidige toestand weergeeft.',
        en: 'How you feel at the moment can be expressed in terms of the charge level of a battery. This can range from "exhausted" to "full of energy". Indicate below which image best represents your current state.'
      }
    }
  )

  questionnairesTemp[BEFORE_MATCH].push(
    {
      type: 'battery',
      required: true,
      id: 'v45',
      label: {
        nl: 'Hoe jij je op dit moment voelt, zou je kunnen uitdrukken in termen van de laadtoestand van een batterij. Dit kan variëren van “uitgeput” tot “vol energie”. Geef hier aan welk plaatje het beste jouw huidige toestand weergeeft.',
        en: 'How you feel at the moment can be expressed in terms of the charge level of a battery. This can range from "exhausted" to "full of energy". Indicate below which image best represents your current state.'
      }
    }
  )

  questionnairesTemp[AFTER_MATCH].push(
    {
      type: 'battery',
      required: true,
      id: 'v46',
      label: {
        nl: 'Hoe jij je op dit moment voelt, zou je kunnen uitdrukken in termen van de laadtoestand van een batterij. Dit kan variëren van “uitgeput” tot “vol energie”. Geef hier aan welk plaatje het beste jouw huidige toestand weergeeft.',
        en: 'How you feel at the moment can be expressed in terms of the charge level of a battery. This can range from "exhausted" to "full of energy". Indicate below which image best represents your current state.'
      }
    }
  )
}

if (process.env.FOLLOW_UP_QUESTIONS === 'true') {
  const newQuestion1 = {
    type: 'radio',
    required: true,
    id: 'v18a',
    label: {
      nl: 'Wil je over deze gebeurtenis met iemand praten?',
      en: 'Would you like to talk to someone about this event?'
    },
    options: [{ value: 'Ja', label: { nl: 'Ja', en: 'Yes' } }, { value: 'Nee', label: { nl: 'Nee', en: 'No' } }],
    showOnlyIf: [
      { v16: 'Ja' }
    ]
  }
  const newQuestion2 = {
    type: 'textfield',
    required: true,
    id: 'v18b',
    label: { nl: 'Met wie zou je hierover willen praten?', en: 'Who would you like to talk to about this?' },
    placeholder: { nl: 'Naam', en: 'Name' },
    showOnlyIf: [
      { v18a: 'Ja' }
    ]
  }

  // find the index of v18 in the INDEPENDENT_OF_A_TRAINING section
  const v18Index = questionnairesTemp[INDEPENDENT_OF_A_TRAINING].findIndex(q => q.id === 'v18')

  // insert the new questions object at the next index
  questionnairesTemp[INDEPENDENT_OF_A_TRAINING].splice(v18Index + 1, 0, newQuestion1)
  questionnairesTemp[INDEPENDENT_OF_A_TRAINING].splice(v18Index + 2, 0, newQuestion2)
}

export const questionnaires = Object.freeze(questionnairesTemp)
