import { useContext } from 'react'
import { useQuery } from 'react-query'
import { UseQueryOptions, UseQueryResult } from 'react-query/types'
import { BackendContext } from './BackendProvider'
import ResponsesPlayerResponsesDTO, { Response } from '../common/types/ResponsesPlayerResponsesDTO'
import { emhFields, emhSumField, emiFields, emiSumField, ostrcFields, ostrcSumField } from '../common/Constants'
import { sum } from 'lodash'
import PolarTrainingSessionsDTO from '../common/types/PolarTrainingSessionsDTO'
import NetworksPlayerNetworksDTO from '../common/types/NetworksPlayerNetworksDTO'

const addPartialSums = (responses: ResponsesPlayerResponsesDTO, fields: string[], sumField: string): void => {
  const filteredResponses: ResponsesPlayerResponsesDTO = responses.filter((response: Response) => fields.some(field => Object.keys(response.contents).includes(field)))
  for (const response of filteredResponses) {
    const responseKeys = Object.keys(response.contents)
    response.contents[sumField] = sum(fields.map(field => responseKeys.includes(field) ? parseInt(response.contents[field]) : 0))
  }
}

export const useResponsesPlayerResponsesQuery = (
  playerIds: number[],
  start: Date,
  end: Date,
  addSumFields: boolean,
  options?: UseQueryOptions<ResponsesPlayerResponsesDTO, Error>
): UseQueryResult<ResponsesPlayerResponsesDTO, Error> => {
  const { backend } = useContext(BackendContext)
  return useQuery<ResponsesPlayerResponsesDTO, Error>(['responsesPlayerResponses', playerIds],
    async () => {
      if (playerIds.length === 0) return []

      const res = await backend.responses.playerResponses(playerIds, start, end)
      if (addSumFields) {
        const ostrcResponses: ResponsesPlayerResponsesDTO = res.data.filter((response: Response) => ostrcFields.every(ostrcField => Object.keys(response.contents).includes(ostrcField)))
        for (const response of ostrcResponses) {
          response.contents[ostrcSumField] = sum(ostrcFields.map(ostrcField => parseInt(response.contents[ostrcField])))
        }
        addPartialSums(res.data, emiFields, emiSumField)
        addPartialSums(res.data, emhFields, emhSumField)
      }
      return res.data
    },
    options)
}

export const usePolarTrainingSessionsQuery = (
  playerIds: number[],
  start: Date,
  end: Date,
  options?: UseQueryOptions<PolarTrainingSessionsDTO, Error>
): UseQueryResult<PolarTrainingSessionsDTO, Error> => {
  const { backend } = useContext(BackendContext)
  return useQuery<PolarTrainingSessionsDTO, Error>(['polarTrainingSessions', playerIds],
    async () => {
      if (playerIds.length === 0) return []

      const res = await backend.polarTrainingSessions.index(playerIds, start, end)
      return res.data
    },
    options)
}

export const useNetworksPlayerNetworksQuery = (
  playerId: number | undefined,
  limit: number,
  options?: UseQueryOptions<NetworksPlayerNetworksDTO, Error>
): UseQueryResult<NetworksPlayerNetworksDTO, Error> => {
  const { backend } = useContext(BackendContext)
  return useQuery<NetworksPlayerNetworksDTO, Error>(['networksPlayerNetworks', playerId, limit],
    async () => {
      if (playerId === undefined) return []

      const res = await backend.networks.playerNetworks(playerId, limit)
      return res.data
    },
    options)
}
